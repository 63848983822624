import React from 'react'
import "./navbar.css"
import Menu from './menuapi.js'
import { useState } from 'react'
function Navbar({ filter, uniqueList, all }) {

    return (
        <div className='container'>
            <div className="navbar">
                <div className='button-group'>
                    {
                        uniqueList.map((data3) => {
                            return (
                                <>
                                    <button onClick={() => filter(data3)} >{data3}</button>
                                </>
                            )
                        })
                    }
                    <button onClick={() => all("all")} >All</button>
                </div>
            </div>
        </div>
    )
}

export default Navbar
