import React from 'react'
import { useState } from 'react'
import Menubar from './menubar'
import Navbar from './navbar'
import "./menubar.css"
import "./navbar.css"
import Menuapi from './menuapi'
const uniqueList = [...new Set(Menuapi.map((data2) => {
    return (data2.category)
}))]
function Restaurent() {
    const [menuData, setmenuData] = useState(Menuapi)

    const filter = (data1) => {
        const menudata = Menuapi.filter((data) => {
            return (data.category === data1)
        })
        setmenuData(menudata)
    }
    const all = (data1) => {
        const menudata = Menuapi.filter((data) => {
            return (data.category !== data1)
        })
        setmenuData(Menuapi)
    }
    return (
        <div>
            <Navbar filter={filter} uniqueList={uniqueList} all={all} />
            <div className='inlineflex main-card--cointainer'>
                <Menubar Menudata={menuData} />
            </div>
        </div>
    )
}

export default Restaurent
