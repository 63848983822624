import React from 'react'
import './menubar.css'
function Menubar({ Menudata }) {

    return (
        <>
            {
                Menudata.map((data) => {
                    const { id, category, description, image, name } = data
                    return (
                        <>
                            <div className="card  ">
                                <span className='num'>{id}</span>
                                <h5 className='menutype'>{category}</h5>
                                <h1><b>{name}</b> </h1>
                                <p className='descrip'>{description}</p>
                                <span className='read card-read'>Read</span>
                                <img src={image} alt="nf" />
                                <span className='order'>Order Now</span>
                            </div>
                        </>
                    )
                }
                )
            }


        </>
    )
}
export default Menubar
