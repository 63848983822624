import './App.css'
import Restaurent from "./component/restaurent";
function App() {
  return (
    <div className="app">
      <Restaurent />
    </div>
  );
}

export default App;
