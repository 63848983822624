
const Menu = [
    {
        id: 1,
        image: "images/maggi.jpg",
        name: "maggi",
        category: "Breakfast",
        price: "12₹",
        description:
            "I love Maggi realy oo yues  Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },

    {
        id: 2,
        image: "images/allupakoida.jpg",
        name: "allu pakoida",
        category: "Evening",
        price: "20₹",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
        id: 3,
        image: "images/corn.jpg",
        name: "corn",
        category: "Breakfast",
        price: "10₹",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
        id: 4,
        image: "../images/chola.jpg",
        name: "chola",
        category: "Lunch",
        price: "50₹",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
        id: 5,
        image: "../images/pizza.jpg",
        name: "pizza",
        category: "Evening",
        price: "80₹",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
        id: 6,
        image: "../images/nonvegthali.jpg",
        name: "Non-Veg Thali",
        category: "Dinner",
        price: "180₹",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
        id: 7,
        image: "../images/sweet.jpg",
        name: "Sweets",
        category: "Dinner",
        price: "60₹",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
        id: 8,
        image: "../images/rajmarice.jpg",
        name: "Rajma Rice",
        category: "Lunch",
        price: "60₹",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
        id: 9,
        image: "../images/samosa.jpg",
        name: "samaso",
        category: "Evening",
        price: "10₹",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
];

export default Menu;